import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        authing:0,
        fetching:0
    },
    reducers: {
        setAuthing: (state, action) => {
            state.authing = action.payload;
        },
        setFetching: (state, action) => {
            state.fetching = action.payload;
        },
        reset: (state, action) => {
            state.authing = 0;
            state.fetching = 0;
        }
    }
});

export const { 
    setAuthing,
    setFetching,
    reset
} = loaderSlice.actions;
export default loaderSlice.reducer;