export default {
    loading:true,
    loadingError:null,
    saving:false,
    savingError:null,
    publishing:false,
    processing:false,
    id:null,
    sourcePath:null,
    parentID:null,
    file:null,
    meta:null,
    previewUrl:null,
    captions:[]

}