import { combineSlices, createSlice } from "@reduxjs/toolkit";
import account from "@store/slices/initial/account";
import brand from "@store/slices/initial/brand";
import creative from "@store/slices/initial/creative";
import event from "@store/slices/initial/event";
import embed from "@store/slices/initial/embed";
import {initial as request} from "@store/slices/initial/request";
import reservation from "@store/slices/initial/reservation";
import donation from "@store/slices/initial/donation";
import user from "@store/slices/initial/user";
import loader from "@store/slices/loader";

export default combineSlices({
  account:createSlice({name:'account',initialState:account}).reducer,
  brand:createSlice({name:'brand',initialState:brand}).reducer,
  creative:createSlice({name:'creative',initialState:creative}).reducer,
  embed:createSlice({name:'embed',initialState:embed}).reducer,
  event:createSlice({name:'event',initialState:event}).reducer,
  request:createSlice({name:'request',initialState:request}).reducer,
  reservation:createSlice({name:'reservation',initialState:reservation}).reducer,
  donation:createSlice({name:'donation',initialState:donation}).reducer,
  user:createSlice({name:'user',initialState:user}).reducer,
  loader:loader
});