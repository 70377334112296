export default {
    loading:true,
    loadingError:null,
    loadingMessages:true,
    purchasesError:null,
    loadingPurchases:true,
    messagesError:null,
    reservation:{
      event:null,
      location:null
    },
    messages:[],
    purchases:[],
    tab:"details",
    activeModal:null
  }