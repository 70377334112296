export default {
    loading:true,
    invalid:false,
    loadingError:null,
    primary:'#00a2ca',
    accent:'#17a2b8',
    brandID:null,
    brandName:null,
    embedUrl:null,
    showZip:true,
    reservationID:null,
    donationID:null,
    organizationID:null
}