export const DEFAULT_USER = {
    id: null,
};
export const DEFAULT_USER_PUBLIC = {
    requireTaxID: false,
    requireDocuments: false,
    optOutSMS: false
};
export const ORG_FIELDS = {
    id: null,
    name: "",
    name_2: "",
    name_creative: null,
    combo: "",
    whichName: "name",
    orgType: "",
    address: "",
    attention_to: "",
    city: "",
    state: "",
    taxid: "",
    website: "",
    zipCode: "",
    taxdocument: null,
    taxidverified: false,
    userID: null,
};
export let initial = {
    id: null,
    step: process.env.GATSBY_PRODUCT_TYPE==='3.0'?2:1,
    direction: 'continue',
    zipCode: "",
    brand: null,
    location: null,
    locations: [],
    event: null,
    donationType: null,
    questions:[],
    validate:false,
    events: [],
    date: null,
    preferredDay:null,
    selectedDays: [],
    org: null,
    orgSize: null,
    orgType: null,
    saved: false,
    user: DEFAULT_USER,
    userPublic: DEFAULT_USER_PUBLIC,
    paymentTo: null,
    attendees: null,
    message: null,
    bypass: false,
    orgs: [],
    share: false,
    erdLoaded: false,
    brandLoaded: false,
    userPublicLoaded: false,
    userOrgsLoaded: false,
    eventsLoaded: false,
    donationTypesLoaded: false,
    orgTypes: [],
    fetchingOrgTypes:true,
    fetchingOrgTypesError:null,
    lookups: [],
    lookupsFulfilled: false,
    looking: false,
    addresses: [],
    confirming: false,
    confirmFulfilled: false,
    confirmError: null,
    brandPage: null,
    eventPage: null,
    accountPage: null,
    currentStep: null,
    fetchingEvent: true,
    fetchingEventError: null,
    fetchingDonationType: true,
    fetchingDonationTypeError: null,
    locationLoaded: false,
    type:'event_reservation'
};