export default {
    loading:true,
    loadingError:null,
    loadingMessages:true,
    messagesError:null,
    donation:{
        brand:null,
        location:null,
        donationType:null
    },
    messages:[],
    tab:"details",
    activeModal:null
}