export default {
    authorized: false,
    authenticated: false,
    processing: false,
    processingLogin: false,
    processingOrg: false,
    processingPerInfo: false,
    fulfilledPerInfo: false,
    errorPerInfo: null,
    accountEmailError: "",
    emailVerified: false,
    currentUser: null,
    user: null,
    authing: true,
    permissions: null,
    orgs: [],
    orgsError: null,
    reservations: [],
    reservationsError: null,
    donations: [],
    donationsError: null,
    fetching: true,
    fetchingOrgsFulfilled: false,
    checkingOrgsFulfilled: false
};