import { configureStore } from "@reduxjs/toolkit";
import reducers from "@store/slices/initial/index";

export default function store() {
  const store = configureStore({
    reducer:reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  store.injectReducers = (asyncReducers) => {
    store.replaceReducer(asyncReducers);
  }
  return store;
}
