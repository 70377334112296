export default {
    events: [],
    event: {},
    schedules: [],
    location: {},
    locations: [],
    loadingLocations: true,
    brand: {},
    selectedDays: [],
    loadingEvent: true,
    fetchingBrand: true,
    selectedLocation: null,
    selectedEvent: null,
    selectedDate: null,
    resId: null,
    step: 1
};